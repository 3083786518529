import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#5BACFA',
      light: '#5BACFA60',
      dark: '#1385f2',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#2C2C2C',
      light: '#2C2C2C60'
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: '10px'
            }
        }
    },
    MuiTypography: {
        styleOverrides: {
            subtitle1: {
                color: '#2C2C2C80'
            }
        }
    }
  }
});

export default theme;