import React, { useCallback, useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import styled from "@emotion/styled";

import {
  ArgumentApiView,
  ArgumentImpactResponseBody,
  ArgumentValueApiView,
  graphArguments,
  impactCalculate,
  PredictionResult,
  SelectedInputValues,
} from "models/graph";
import {
  Autocomplete,
  createFilterOptions,
  Divider,
  Icon,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const FieldsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  margin: 10px 0;
`;

interface Props {
  id: string;
}
const defaultValue: ArgumentValueApiView = {};

const ArgumentsImpactModal = ({ id }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [graphArgumentsList, setGraphArgumentsList] = useState<
    ArgumentApiView[]
  >([]);
  const [outputValueNode, setOutputValueNode] = useState<ArgumentApiView>({});
  const [selectedValues, setSelectedValues] = useState<SelectedInputValues>({});
  const [predictionResult, setPredictionResult] =
    useState<PredictionResult | null>(null);
  const filter = createFilterOptions<ArgumentValueApiView>();
  const [argumentImpact, setArgumentImpact] =
    useState<ArgumentImpactResponseBody | null>(null);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setOutputValueNode({});
    clearForm();
    setPredictionResult(null);
    setIsModalOpen(false);
  };

  const getOutputArgumentName = useCallback(
    () =>
      outputValueNode &&
      graphArgumentsList.find((a) => a.id === outputValueNode.id)?.name,
    [graphArgumentsList, outputValueNode]
  );

  const fetchArguments = async () => {
    if (!id) return;
    try {
      const response = await graphArguments(id);
      setGraphArgumentsList(response);
    } catch (error) {
      //
    }
  };

  const getDefaultValues = useCallback(() => {
    const defaultValues: SelectedInputValues = {};
    graphArgumentsList.forEach((a) => {
      defaultValues[a.id!] = { ...defaultValue };
    });
    return defaultValues;
  }, [graphArgumentsList]);

  const fillDefaultValues = useCallback(() => {
    setSelectedValues((prev) => {
      return { ...getDefaultValues(), ...prev };
    });
  }, [getDefaultValues]);

  const clearForm = useCallback(() => {
    setSelectedValues((prev) => {
      return { ...getDefaultValues() };
    });
  }, [getDefaultValues]);

  useEffect(() => {
    fillDefaultValues();
  }, [fillDefaultValues]);

  useEffect(() => {
    fetchArguments();
  }, []);

  const handleChange = (value: ArgumentApiView | null) => {
    setOutputValueNode({ ...value } || {});
  };

  const changeValue = useCallback(
    (argumentId: string, newValue: ArgumentValueApiView) => {
      setSelectedValues((prev) => {
        return {
          ...prev,
          [argumentId]: { ...newValue },
        };
      });
    },
    [setSelectedValues]
  );

  const getInputArgumentValues = useCallback(
    () =>
      Object.values(selectedValues)
        .filter((v) => v.id || (v.name && v.value))
        .map((v) => {
          return { ...v } as ArgumentValueApiView;
        }),
    [selectedValues]
  );

  const handleSend = async () => {
    try {
      const response = await impactCalculate(id, {
        outputArgumentId: outputValueNode.id || "",
      });
      setArgumentImpact(response);
    } catch (error) {
      //
    }
  };

  const isOutputSelected: () => boolean = useCallback(() => {
    return !!outputValueNode.id;
  }, [outputValueNode]);

  return (
    <>
      <Button variant="contained" onClick={handleModalOpen}>
        Calculate impact
      </Button>

      <Dialog open={isModalOpen}>
        <DialogTitle>Argument Impact</DialogTitle>
        <DialogContent className="dialog-contnet">
          <FieldsWrapper key={`wrapper-output-select`}>
            <Autocomplete
              disablePortal
              id={`select-value-output`}
              options={graphArgumentsList}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Regular option
                return option.name || "";
              }}
              fullWidth
              value={outputValueNode}
              onChange={(e, value) => handleChange(value)}
              renderInput={(params) => (
                <TextField {...params} label="Output argument" />
              )}
            />
          </FieldsWrapper>

          {argumentImpact && (
            <>
              <Divider />
              <h4>Argument impact on: {getOutputArgumentName()}</h4>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>lp</TableCell>
                      <TableCell>Argument name</TableCell>
                      <TableCell>Impact score</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {argumentImpact
                      .sort((a, b) => b.avgImpact - a.avgImpact)
                      .map((argImpact, idx) => (
                        <TableRow
                          key={`row-${idx}`}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{idx + 1}</TableCell>
                          <TableCell>{argImpact.name}</TableCell>
                          <TableCell>{argImpact.avgImpact}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleModalClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSend}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ArgumentsImpactModal;
