import React, { useCallback, useEffect, useState, CSSProperties } from "react";

import { ArgumentApiView, ArgumentValueApiView, graphArguments, predict, } from "models/graph";
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, Button, Table, TableHead, TableBody, TableRow, TableCell, TextField } from "@mui/material";
import { useCSVReader } from 'react-papaparse';
import styled from "@emotion/styled";

const styles = {
  csvReader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  } as CSSProperties,
  browseFile: {
    width: '30%',
  } as CSSProperties,
  acceptedFile: {
    border: '1px solid #ccc',
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: '80%',
  } as CSSProperties,
  remove: {
    borderRadius: 0,
    padding: '0 20px',
  } as CSSProperties,
  progressBarBackgroundColor: {
    backgroundColor: 'red',
  } as CSSProperties,
};
const FieldsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  margin: 10px 0;
`;

interface Props {
  id: string;
}

const PredictionFromCSV = ({ id }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCSVLoaded, setIsCSVLoaded] = useState<boolean>(false);
  const { CSVReader } = useCSVReader();
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [rows, setRows] = useState<string[][]>([]);
  const [outputValueNode, setOutputValueNode] = useState<ArgumentApiView>({});
  const [graphArgumentsList, setGraphArgumentsList] = useState<ArgumentApiView[]>([]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCsvData([]);
    setIsCSVLoaded(false);
    setOutputValueNode({})
  };

  useEffect(() => {
    setHeaders(csvData[0])
    setRows(csvData.slice(1).map((row) => {
      return ['-', ...row];
    }))

  }, [csvData]);

  const fetchArguments = async () => {
    if (!id) return;
    try {
      const response = await graphArguments(id);
      setGraphArgumentsList(response);
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    fetchArguments();
  }, []);

  const isOutputSelected: () => boolean = useCallback(() => {
    return !!outputValueNode.id;
  }, [outputValueNode]);

  const handleChange = (value: ArgumentApiView | null) => {
    setOutputValueNode({ ...value } || {});
  };

  const runPrediction = async () => {
    rows.forEach(async (row, index) => {
      let predictionObject = createPredictionObjectFromRow(row.slice(1))
      const result = await predictionForRow(predictionObject)
      const newRows = [...rows]
      newRows[index][0] = JSON.stringify(result)
      setRows(newRows)
    })

    setRows(rows)
  }

  function createPredictionObjectFromRow(row: string[]): ArgumentValueApiView[] {
    const objects: ArgumentValueApiView[] = []
    row.forEach((value, index) => {
      if (value) {
        const argName = headers[index]
        const arg = graphArgumentsList.find(argument => argument.name === argName)
        const argument: ArgumentValueApiView = {
          name: argName,
          value: value,
          id: arg?.id
        }
        objects.push(argument)
      }
    })

    return objects
  }

  const predictionForRow: any = async (values: ArgumentValueApiView[]) => {
    try {
      const response = await predict(id, {
        outputArgumentId: outputValueNode.id || "",
        entities: [
          {
            key: "modal-entity",
            values: values,
          },
        ],
      });
      const prediction = response.find((p) => p.entity.key === "modal-entity")!.prediction
      return prediction;
    } catch (error) {
      //
    }
  };

  const displayResult = (res: string) => {
    const obj = JSON.parse(res)
    return <div>
      <Table>

        {Object.entries(obj).map(([key, value]) => (
          <TableRow key={key}>
            <TableCell>{key}</TableCell>
            <TableCell>{value as string}</TableCell>
          </TableRow>)
        )}
      </Table>
    </div>
  }

  return (
    <>
      <Button variant="contained" onClick={handleModalOpen}>
        CSV prediction
      </Button>

      <Dialog open={isModalOpen} fullScreen>
        <DialogTitle>Input values</DialogTitle>
        <DialogContent className="csv-dialog-content">
          {
            isCSVLoaded ? (
              <div>
                <div>
                  {!isOutputSelected() && (
                    <FieldsWrapper key={`wrapper-output-select`}>
                      <Autocomplete
                        disablePortal
                        id={`select-value-output`}
                        options={graphArgumentsList}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          return option.name || "";
                        }}
                        fullWidth
                        value={outputValueNode}
                        onChange={(e, value) => handleChange(value)}
                        renderInput={(params) => (
                          <TextField {...params} label="Output argument" />
                        )}
                      />
                    </FieldsWrapper>
                  )}
                  {isOutputSelected() && (
                    <div>Selected output: {outputValueNode.name}
                      <br />
                      <Button variant="contained" onClick={runPrediction}>Run prediction</Button>
                    </div>
                  )}
                </div>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Prediction</TableCell>
                      {headers && headers.map((value: string, index: number) => (
                        <TableCell key={value}>{value}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {
                            (row[0] === '-')
                              ? <div>-</div>
                              : <div>{displayResult(row[0])}</div>
                          }
                        </TableCell>
                        {Object.values(row.slice(1)).map((value, idx) => (
                          <TableCell key={idx}>{value}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>

                </Table>

              </div>
            ) :
              (
                <div>
                  <CSVReader
                    onUploadAccepted={async (results: any) => {
                      setCsvData(results.data);
                      setIsCSVLoaded(true)
                    }}
                  >
                    {({
                      getRootProps,
                      acceptedFile,
                      ProgressBar,
                      getRemoveFileProps,
                    }: any) => (
                      <>
                        <div style={styles.csvReader}>
                          <button type='button' {...getRootProps()} style={styles.browseFile}>
                            Browse file
                          </button>
                          <div style={styles.acceptedFile}>
                            {acceptedFile && acceptedFile.name}
                          </div>
                          <button {...getRemoveFileProps()} style={styles.remove}>
                            Remove
                          </button>
                        </div>
                        <ProgressBar style={styles.progressBarBackgroundColor} />
                      </>
                    )}
                  </CSVReader>
                </div>
              )
          }
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleModalClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PredictionFromCSV;
