import Keycloak from "keycloak-js";

export type Role = "admin" | "specialist" | "researcher";

const AUTHORIZATION_SERVER_URL =
  process.env.REACT_APP_AUTHORIZATION_SERVER_URL || "http://localhost:8088";

const _kc = new Keycloak({
  url: AUTHORIZATION_SERVER_URL,
  clientId: "graphai-frontend",
  realm: "graphai-realm",
});

const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/auth/silent-check-sso.html",
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      if (!authenticated && !unauthenticatedPath()) {
        doLogin();
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const unauthenticatedPath = () =>
  ["/terms-of-service", "/privacy-notice", "/signup"].some(
    (e) => e === window.location.pathname
  ) || window.location.pathname.includes("questionnaire/");

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: () => void) =>
  _kc.updateToken(10).then(successCallback).catch(doLogin);

const getUsername = () => _kc.profile?.username;

const hasRoles = (checkRoles: Role[], exact = false): boolean => {
  const roles = (_kc.resourceAccess?.["graphai-frontend"]?.roles ||
    []) as Role[];

  if (exact) {
    return roles.every((role) => checkRoles.includes(role));
  }
  return roles.some((role) => checkRoles.includes(role));
};

const isAdmin = () => hasRoles(["admin"]);

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRoles,
  isVivelioAdmin: isAdmin,
  unauthenticatedPath,
};

export default UserService;
