import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getGraphInfo, getGraphMeta, GraphInfo, Metrics, renameModel, deleteModel } from "models/graph";
import ArgumentsModal from "predictions/ArgumentsModal";
import ArgumentsImpactModal from "predictions/ArgumentsImpactModal";
import PredictionFromCSV from "predictions/PredictionFromCSV";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Tab, TabGroup } from "components/tabs";

const Model = () => {
  const [metrics, setMetrics] = useState<Metrics>({})
  const { graphId } = useParams<{ graphId: string }>()
  const [nameChangeDialogOpen, setNameChangeDialogOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [newModelName, setNewModelName] = useState<string>('')

  const navigate = useNavigate()

  const [{ name }, setGraphInfo] = useState<GraphInfo>({ name: "", id: graphId! });

  const fetchMetrics = useCallback(async () => {
    if (!graphId) return;
    try {
      const response = await getGraphMeta(graphId);
      setMetrics(response);
    } catch (error) {
      //
    }
  }, [graphId]);

  const fetchGraphInfo = useCallback(async () => {
    if (!graphId) return;
    try {
      const response = await getGraphInfo(graphId);
      setGraphInfo(response);
    } catch (error) {
      //
    }
  }, [graphId]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  useEffect(() => {
    fetchGraphInfo();
  }, [fetchGraphInfo]);

  const isDataLoadedToGraph = useCallback(() => {
    return Object.values(metrics).some((value) => value !== 0);
  }, [metrics]);

  const excludedKeys: string[] = ["VALUE", "RANGE", "SIMILAR_ARGUMENT_VALUE", "ENTITY_VALUE", "SIMILAR_RANGE"];

  const handleEditModelNameSave = async (graphId: string, name: string | undefined) => {
    setNameChangeDialogOpen(false)
    if (!name) return
    try {
      await renameModel(graphId, name)
      setGraphInfo((graph) => ({...graph, name}))
    } catch (e: any) {
      console.error(e.message)
    }
  }

  const handleDeleteModel = async (graphId: string) => {
    setDeleteDialogOpen(false)
    try {
      await deleteModel(graphId)
      navigate("/projects/")
    } catch (e: any) {
      console.error(e.message)
    }
  }

  const handleNameChangeModalOpen = () => {
    setNameChangeDialogOpen(true)
  }

  const handleNameChangeModalClose = () => {
    setNameChangeDialogOpen(false)
  }

  const handleDeleteModalOpen = () => {
    setDeleteDialogOpen(true)
  }

  const handleDeleteModalClose = () => {
    setDeleteDialogOpen(false)
  }

  if (!graphId) return null;

  return (
    <>
      <section style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '20px' }}>
        <h1>Name: {name}</h1>
        <div
          style={{
            background: '#2c2c2c',
            borderRadius: '0 8px 0 8px',
            padding: '10px',
            width: '38px',
            height: '38px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
          onClick={handleNameChangeModalOpen}
        >
          <EditIcon color="primary"/>
        </div>
        <div
          style={{
            background: '#2c2c2c',
            borderRadius: '0 8px 0 8px',
            padding: '10px',
            width: '38px',
            height: '38px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
          onClick={handleDeleteModalOpen}
        >
          <DeleteIcon color="primary"/>
        </div>
      </section>
      {!isDataLoadedToGraph() ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>

          {/* change model name */}
          <Dialog open={nameChangeDialogOpen}>
            <DialogTitle>Change model name</DialogTitle>
            <DialogContent className="dialog-contnet">
              <TextField autoFocus margin="dense" value={newModelName} onChange={event => setNewModelName(event.target.value as string)} label="Model name" fullWidth variant="standard" />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleNameChangeModalClose}>Cancel</Button>
              <Button variant="contained" onClick={() => handleEditModelNameSave(graphId!, newModelName)}>
                Send
              </Button>
            </DialogActions>
          </Dialog>

          {/* delete model */}
          <Dialog open={deleteDialogOpen}>
            <DialogTitle>Are you sure you want to delete model?</DialogTitle>
            <DialogActions>
              <Button onClick={handleDeleteModalClose}>Cancel</Button>
              <Button variant="contained" onClick={() => handleDeleteModel(graphId!)}>
                Delete model
              </Button>
            </DialogActions>
          </Dialog>

          <TabGroup>
            <Tab label="Summary">
              <h3>Model summary</h3>
              <div className="boxes">
                {Object.entries(metrics).filter(([key]) => !excludedKeys.includes(key)).map(([key, value], idx) => (
                  <div
                    className="box styled"
                    key={idx}
                  >
                    <h4 style={{ textTransform: 'lowercase' }}>{key}</h4>
                    <h1>{value}</h1>
                  </div>
                ))}
              </div>
            </Tab>

            <Tab label="Actions">
              <h3>Model actions</h3>

              {Object.values(metrics).some((value) => value !== 0) && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'flex-start' }}>
                  <ArgumentsModal id={graphId} />
                  <ArgumentsImpactModal id={graphId} />
                  <PredictionFromCSV id={graphId} />
                </div>
              )}
            </Tab>
          </TabGroup>
        </>
      )}
    </>
  );
};

export default React.memo(Model);
