import { Box } from '@mui/material'
import ModelSetData from './ModelSetData'

const ModelCreator = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: "40px"
      }}>
      <h1>Create AI model in one-click</h1>
      <ModelSetData />
    </Box>
  )
}

export default ModelCreator
