import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import UserService from 'UserService'
import axios from 'axios'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.get['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'
axios.interceptors.request.use((config) =>
  UserService.unauthenticatedPath() && !UserService.getToken()
    ? Promise.resolve(config)
    : new Promise((resolve) =>
        UserService.updateToken(function () {
          const token = UserService.getToken()
          if (token && config && config.headers)
            config.headers.Authorization = `Bearer ${token}`
          resolve(config)
        })
      )
)

UserService.initKeycloak(() =>
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
)
