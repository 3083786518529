import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import Layout from 'Layout'
import ModelCreator from 'modelCreator/ModelCreator'
import ProjectView from 'project/ProjectView'
import ModelSummary from 'modelDetails/ModelSummary'

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ModelCreator />} />
          <Route path="/projects/:projectId" element={<ProjectView />} />
          <Route path="/graph/:graphId" element={<ModelSummary />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default RoutesComponent
