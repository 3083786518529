import React from 'react'
import { styled } from '@mui/material/styles'
import { Outlet } from 'react-router-dom'
import MenuAppBar from 'MenuAppBar'
import Footer from 'components/Footer'

const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
`

const Content = styled('div')`
  width: 100%;
  padding: 50px 50px 20px;
  max-width: 1800px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Layout = () => {
  return (
    <>
      <MenuAppBar>
        <Wrapper>
          <Content>
            <div>
              <Outlet />
            </div>
            <Footer />
          </Content>
        </Wrapper>
      </MenuAppBar>
    </>
  )
}

export default Layout
