import { getAllProjects, Project } from 'models/project'
import React, { createContext, useEffect, useState } from 'react'

interface ProjectsProviderProps {
  children: React.ReactNode
}
const ProjectsProvider = ({ children }: ProjectsProviderProps) => {
  const [projects, setProjects] = useState<Project[]>([])

  useEffect(() => {
    fetchAllProjects()
  },[])

  const fetchAllProjects = async () => {    
    try {
      setProjects(await getAllProjects())
    } catch (error) {
      console.error('System - Failed to fetch sections', error)
    }
  }

  const addProject = (project: Project) => {
    setProjects(state => ([...state, project]))
  }

  const updateProjects = (project: Project) => {
    setProjects(state => {
      return state.map(e => {
        if(e.id === project.id) {
          return project
        }
        return e
      })
    })
  }

  const value = { projects, updateProjects, addProject }
  return <ProjectsStateContext.Provider value={value}>
    {children}
  </ProjectsStateContext.Provider>
}

export default ProjectsProvider

const ProjectsStateContext = createContext<{ projects: Project[],
   updateProjects: (project: Project) => void,
   addProject: (project: Project) => void,
 }>({ projects: [], updateProjects: (project: Project) => {}, addProject: (project: Project) => {} })

export const useProjectsState = () => {
  const context = React.useContext(ProjectsStateContext)
  if (context === undefined) {
    throw new Error('useProjectsState must be used within a CountProvider')
  }
  return context
}