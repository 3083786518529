import { useState } from "react"
import { TabProps } from "./Tab"

import './styles.css'

interface Props {
    activeCard?: number
    children: React.ReactElement<TabProps>[]
}
export const TabGroup = ({ children, activeCard = 0 }: Props) => {
  const [active, setActive] = useState<number>(activeCard)

  const getHeaders = (): string[] => {
    return children.map(c => c.props.label)
  }

  const isActive = (index: number): string => {
    return index === active ? 'active' : ''
  }

  return (<div className="viv tab">
    <div className="viv tab-header">
      {getHeaders().map((h, index) => 
        <div
          key={h}
          onClick={() => setActive(index)}
          className={`viv tab-header-label ${isActive(index)}`}>
          {h}
        </div>
      )}
    </div>

    <div className="viv tab-content">
      {children[active]}
    </div>
  </div>
  )
}

export default TabGroup