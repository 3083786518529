import { ChangeEvent, useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate, NavLink, useParams } from 'react-router-dom'

import { changeProjectName, getProject, getProjectModels, Project, deleteProject } from 'models/project'
import { createGraph } from 'models/graph'
import { handleLoadData } from 'models/data'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useProjectsState } from 'project/ProjectsProvider'
import { AxiosError } from 'axios'

const ProjectView = () => {
  const [project, setProject] = useState<Project | null>(null)
  const [models, setModels] = useState<Project[]>([])
  const [file, setFile] = useState<File | null>(null)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [newProjectName, setNewProjectName] = useState<string>('')
  const { projectId } = useParams<string>()
  const { updateProjects } = useProjectsState()
  const [modelCreationError, setModelCreationError] = useState<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    if(!projectId) return
    handleGetProject(projectId)
    handleGetProjectModels(projectId)
  }, [projectId])

  const handleGetProject = async (projectId: string) => {
    try {
     const response = await getProject(projectId)
     setProject(response)
    } catch (error) {
      setProject(null)
    }
  }

  const handleGetProjectModels = async (projectId: string) => {
    try {
     const response = await getProjectModels(projectId)
     setModels(response)
    } catch (error) {
      setModels([])
    }
  }

  const handleModalOpen = () => {
    setDialogOpen(true)
  }

  const handleModalClose = () => {
    setDialogOpen(false)
  }

  const handleEditProjectNameSave = async (projectId: string, newProjectName: string | undefined) => {
    setDialogOpen(false)
    if(!newProjectName) return
    try {
      const response = await changeProjectName(projectId, newProjectName)
      setProject(response)
      updateProjects(response)
    } catch (e: any) {
      console.error(e.message)
    }
  }

  const handleDeleteModel = async (projectId: string) => {
    setDeleteDialogOpen(false)
    try {
      await deleteProject(projectId)
      navigate("/")
    } catch (e: any) {
      console.error(e.message)
    }
  }

  const handleDeleteModalOpen = () => {
    setDeleteDialogOpen(true)
  }

  const handleDeleteModalClose = () => {
    setDeleteDialogOpen(false)
  }

  const handleModelCreationErrorModalOpen = () => {
    setModelCreationError(true)
  }

  const handleModelCreationErrorModalClose = () => {
    setModelCreationError(false)
    window.location.reload()
  }
  
  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const allowedExtensions = /(\.csv)$/i
      const file = e.target.files[0]

      if(!allowedExtensions.exec(file.name)){
        e.target.value = ""
        setFile(null)
        console.error("Error uploading new file")
      } else {
        try {
          setFile(file)
          try {
            const newGraph = await createGraph(file.name, projectId)
            setModels(state => ([...state, newGraph]))
            try {
              await handleLoadData(file, newGraph.id)
              setFile(null)
            } catch (error) {
              console.error("Error uploading new file")
            }
          } catch (error) {
            const err = error as AxiosError
            if (err.response?.status === 409) {
              setModelCreationError(true)
            }
            
            console.error("Error creating new data model - model limit reached.")
          }
        } catch (e: any) {
          console.error(e.message)
        }
      }
    }
  }

  return <Box display='flex' flexDirection='column' gap={(theme) => theme.spacing(2)}>
    <section style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '20px' }}>
      <h2>Project name: {project?.name || ''}</h2>
      <div 
        style={{
          background: '#2c2c2c',
          borderRadius: '0 8px 0 8px',
          padding: '10px',
          width: '38px',
          height: '38px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={handleModalOpen}
      >
        <EditIcon color="primary"/>
      </div>
      <div
          style={{
            background: '#2c2c2c',
            borderRadius: '0 8px 0 8px',
            padding: '10px',
            width: '38px',
            height: '38px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
          onClick={handleDeleteModalOpen}
        >
          <DeleteIcon color="primary"/>
        </div>
    </section>

    {/* change project name */}
    <Dialog open={dialogOpen}>
      <DialogTitle>Change project name</DialogTitle>
      <DialogContent className="dialog-contnet">
        <TextField autoFocus margin="dense" value={newProjectName} onChange={event => setNewProjectName(event.target.value as string)} label="Project name" fullWidth variant="standard" />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose}>Cancel</Button>
        <Button variant="contained" onClick={() => handleEditProjectNameSave(projectId!, newProjectName)}>
          Send
        </Button>
      </DialogActions>
    </Dialog>

    {/* delete model */}
    <Dialog open={deleteDialogOpen}>
        <DialogTitle>Are you sure you want to delete project?</DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteModalClose}>Cancel</Button>
          <Button variant="contained" onClick={() => handleDeleteModel(projectId!)}>
            Delete project
          </Button>
        </DialogActions>
    </Dialog>

    {/* model creation error */}
    <Dialog open={modelCreationError}>
        <DialogTitle>You have reached limit of data models. Contact support or your sales representative.</DialogTitle>
          <DialogActions>
            <Button onClick={handleModelCreationErrorModalClose}>OK</Button>
        </DialogActions>
    </Dialog>

    <Box display='flex' gap={(theme) => theme.spacing(4)} justifyContent="space-between" alignItems="center">
      <Typography variant='h5'>Models</Typography> 
      <Button variant='contained' startIcon={<AddIcon/>} component="label">
        New model
        <input style={{ display: "none" }} id="file-upload" type="file" accept=".csv" onChange={onFileChange} />
      </Button>
    </Box>

    <section className="boxes">
      {file ? (
        <>
          <CircularProgress />
          <p>Your file is processing. Please wait.</p>
        </>
      ) : (
        models && models.map(model => (
          <div className="box" key={model.id}>
            <NavLink to={`/graph/${model.id}`}><h3>{model.name}</h3></NavLink>
          </div>
        ))
      )}
    </section>
  </Box>
}

export default ProjectView