import axios from 'axios'
import { GraphSchema } from './graph'

export interface Project {
  id: string
  name: string
}

export interface SampleDataset {
  sampleKey: string
  displayName: string
}

export const getAllProjects = async () => {
  const response = await axios.get<Project[]>('/api/projects')
  return response.data
}

export const getProject = async (projectId: string) => {
  const response = await axios.get<Project>(`/api/projects/${projectId}`)
  return response.data
}

export const createProject = async (name: string) => {
  const response = await axios.post('/api/projects/create', name)
  return response.data
}

export const loadSample = async (projectId: string, sampleKey: string) => {
  const response = await axios.post(
    `/api/samples/${projectId}/load?name=${sampleKey}`
  )
  return response.data
}

export const getSampleDatasets = async () => {
  const response = await axios.get<SampleDataset[]>(`/api/samples/all`)
  return response.data
}

export const getProjectModels = async (projectId: string) => {
  const response = await axios.get<GraphSchema[]>(
    `/api/projects/${projectId}/models`
  )
  return response.data
}

export const addProjectModel = async (projectId: string, name: string) => {
  await axios.post(`/api/projects/${projectId}/models`, name)
}

export const changeProjectName = async (
  projectId: string,
  name: string
): Promise<Project> => {
  const response = await axios.put<Project>(
    `/api/projects/${projectId}/rename`,
    name
  )
  return response.data
}

export const deleteProject = async (projectId: string) => {
  await axios.delete(`/api/projects/${projectId}`)
}
