import React, { useCallback, useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "@emotion/styled";

import {
  ArgumentApiView,
  ArgumentImpactResponseBody,
  ArgumentValueApiView,
  graphArguments,
  impactCalculate,
  predict,
  PredictionResult,
  SelectedInputValues,
} from "models/graph";
import {
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const FieldsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  margin: 10px 0;
`;

interface Props {
  id: string;
}
const defaultValue: ArgumentValueApiView = {};

const ArgumentsModal = ({ id }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [graphArgumentsList, setGraphArgumentsList] = useState<
    ArgumentApiView[]
  >([]);
  const [outputValueNode, setOutputValueNode] = useState<ArgumentApiView>({});
  const [selectedValues, setSelectedValues] = useState<SelectedInputValues>({});
  const [predictionResult, setPredictionResult] =
    useState<PredictionResult | null>(null);
  const [argumentImpact, setArgumentImpact] =
    useState<ArgumentImpactResponseBody | null>(null);
  const [isImpactReady, setImpactReady] = useState(false);
  const [isShowImpact, setShowImpact] = useState(false);
  const [argumentImpactForDisplay, setArgumentImpactForDisplay] =
    useState<ArgumentImpactResponseBody | null>(null);
  const [selectedArgumentNames, setSelectedArguemntNames] = useState<string[]>(
    []
  );
  const filter = createFilterOptions<ArgumentValueApiView>();

  const handleModalOpen = () => {
    setIsModalOpen(true);
    setImpactReady(false);
    setShowImpact(false);
    setArgumentImpact(null);
    setArgumentImpactForDisplay(null);
  };

  const handleModalClose = () => {
    setOutputValueNode({});
    clearForm();
    setPredictionResult(null);
    setIsModalOpen(false);
  };

  const getOutputArgumentName = useCallback(
    () =>
      outputValueNode &&
      graphArgumentsList.find((a) => a.id === outputValueNode.id)?.name,
    [graphArgumentsList, outputValueNode]
  );

  const fetchArguments = async () => {
    if (!id) return;
    try {
      const response = await graphArguments(id);
      setGraphArgumentsList(response);
    } catch (error) {
      //
    }
  };

  const getDefaultValues = useCallback(() => {
    const defaultValues: SelectedInputValues = {};
    graphArgumentsList.forEach((a) => {
      defaultValues[a.id!] = { ...defaultValue };
    });
    return defaultValues;
  }, [graphArgumentsList]);

  const fillDefaultValues = useCallback(() => {
    setSelectedValues((prev) => {
      return { ...getDefaultValues(), ...prev };
    });
  }, [getDefaultValues]);

  const clearForm = useCallback(() => {
    setSelectedValues((prev) => {
      return { ...getDefaultValues() };
    });
  }, [getDefaultValues]);

  useEffect(() => {
    fillDefaultValues();
  }, [fillDefaultValues]);

  useEffect(() => {
    fetchArguments();
  }, []);

  useEffect(() => {
    setImpactReady(false);
    setSelectedArguemntNames(
      Object.values(selectedValues).map(({ name }) => name || "-")
    );
  }, [selectedValues]);

  useEffect(() => {
    if (argumentImpact) {
      setImpactReady(false);
      setArgumentImpactForDisplay(
        argumentImpact.filter(
          ({ name }) => selectedArgumentNames.indexOf(name) === -1
        )
      );
      setImpactReady(true);
    }
  }, [argumentImpact, selectedArgumentNames]);

  const handleChange = (value: ArgumentApiView | null) => {
    setOutputValueNode({ ...value } || {});
    setImpactReady(false);
    value?.id &&
      impactCalculate(id, {
        outputArgumentId: value?.id,
      }).then((response) => setArgumentImpact(response));
  };

  const changeValue = useCallback(
    (argumentId: string, newValue: ArgumentValueApiView) => {
      setSelectedValues((prev) => {
        return {
          ...prev,
          [argumentId]: { ...newValue },
        };
      });
    },
    [setSelectedValues]
  );

  const getInputArgumentValues = useCallback(
    () =>
      Object.values(selectedValues)
        .filter((v) => v.id || (v.name && v.value))
        .map((v) => {
          return { ...v } as ArgumentValueApiView;
        }),
    [selectedValues]
  );

  const handleSend = async () => {
    try {
      const response = await predict(id, {
        outputArgumentId: outputValueNode.id || "",
        entities: [
          {
            key: "modal-entity",
            values: getInputArgumentValues(),
          },
        ],
      });
      setPredictionResult(
        response.find((p) => p.entity.key === "modal-entity")!.prediction
      );
    } catch (error) {
      //
    }
  };

  const toggleShowImpact = () =>
    setShowImpact((prev) => {
      return !prev;
    });
  const isOutputSelected: () => boolean = useCallback(() => {
    return !!outputValueNode.id;
  }, [outputValueNode]);

  return (
    <>
      <Button variant="contained" onClick={handleModalOpen}>
        Single prediction
      </Button>

      <Dialog open={isModalOpen}>
        <DialogTitle>Input values</DialogTitle>
        <DialogContent className="dialog-contnet">
          {!isOutputSelected() && (
            <FieldsWrapper key={`wrapper-output-select`}>
              <Autocomplete
                disablePortal
                id={`select-value-output`}
                options={graphArgumentsList}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Regular option
                  return option.name || "";
                }}
                fullWidth
                value={outputValueNode}
                onChange={(e, value) => handleChange(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Output argument" />
                )}
              />
            </FieldsWrapper>
          )}
          {isOutputSelected() &&
            graphArgumentsList
              .filter((a) => a.id !== outputValueNode.id)
              .map(({ id, name, existingValues }, idx) => (
                <FieldsWrapper key={`wrapper-${idx}`}>
                  <Autocomplete
                    freeSolo
                    disablePortal
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id={`value-input-${idx}`}
                    options={existingValues!!}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Regular option
                      return option.value || "";
                    }}
                    onChange={(
                      event: any,
                      newValue: ArgumentValueApiView | string | null
                    ) => {
                      if (newValue) {
                        if (typeof newValue === "string") {
                          changeValue(id!, {
                            name,
                            value: newValue,
                          });
                        } else {
                          changeValue(id!, newValue);
                        }
                      } else changeValue(id!, { ...defaultValue });
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.value
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          name,
                          value: inputValue,
                        });
                      }

                      return filtered;
                    }}
                    fullWidth
                    value={selectedValues[id!]}
                    renderInput={(params) => (
                      <TextField {...params} label={name} />
                    )}
                  />
                </FieldsWrapper>
              ))}
          {predictionResult && (
            <>
              <Divider />
              <h4>Prediction for: {getOutputArgumentName()}</h4>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Order</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Score</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(predictionResult)
                      .sort(([_0, a], [_1, b]) => b - a)
                      .map(([key, value], idx) => (
                        <TableRow
                          key={`row-${idx}`}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{idx + 1}</TableCell>
                          <TableCell>{key}</TableCell>
                          <TableCell>{value}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {argumentImpactForDisplay && isImpactReady && isShowImpact && (
            <>
              <Divider />
              <h4>Arguments listed below will improve accuracy</h4>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>lp</TableCell>
                      <TableCell>Argument name</TableCell>
                      <TableCell>Impact score</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {argumentImpactForDisplay
                      .sort((a, b) => b.avgImpact - a.avgImpact)
                      .map((argImpact, idx) => (
                        <TableRow
                          key={`row-${idx}`}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{idx + 1}</TableCell>
                          <TableCell>{argImpact.name}</TableCell>
                          <TableCell>{argImpact.avgImpact}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {!isImpactReady && isShowImpact && (
            <>
              <CircularProgress />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleModalClose}>Cancel</Button>
          <Button variant="contained" onClick={toggleShowImpact}>
            Improve
          </Button>
          <Button variant="contained" onClick={handleSend}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ArgumentsModal;
