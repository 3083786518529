import CssBaseline  from "@mui/material/CssBaseline";
import { ThemeProvider } from '@mui/material/styles';


import RoutesComponent from "routes";
import theme from "theme";
import "./App.css"
import ProjectsProvider from "project/ProjectsProvider";

const App = () => {
  return <ThemeProvider theme={theme}>
      <CssBaseline />
      <ProjectsProvider>
        <RoutesComponent />
      </ProjectsProvider>
    </ThemeProvider>
}

export default App;
