import { ReactNode } from "react"

export interface TabProps {
    label: string,
    children: ReactNode
}
export const Tab = ({ children }: TabProps) => {
  return (<>
    { children }
  </>
  )
}
export default Tab