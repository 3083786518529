import { useState } from 'react'
import { useNavigate } from "react-router"
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

import Dropzone from 'components/Dropzone'
import ModelSampleDataModal from './ModelSampleDataModal'
import { createProject } from 'models/project'
import { createGraph } from 'models/graph'
import { handleLoadData } from "models/data"
import CircularProgress from '@mui/material/CircularProgress'
import { useProjectsState } from 'project/ProjectsProvider'

const Separator = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 0,
  gap: 1,
  color: '#b1b3b9',
  height: '100px',

  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',

  '&>span': {
    backgroundColor: '#cfd0d2',
    width: '1px',
    height: '50%',
  },
})

const ModelSetData = () => {
  const [file, setFile] = useState<File | null>(null)
  const { addProject } = useProjectsState()
  const navigate = useNavigate()

  const onFileChange = async (file: File | null) => {
    if (file) {
      const allowedExtensions = /(\.csv)$/i

      if(!allowedExtensions.exec(file.name)){
        setFile(null)
        console.error("Błąd przy dodawaniu pliku")
      } else {
        try {
          setFile(file)
          const projectResponse = await createProject(file.name)
          addProject(projectResponse)

          try {
            const graph = await createGraph(file.name, projectResponse.id)
            try {
              await handleLoadData(file, graph.id)
              
            } catch (error) {
              console.error("Błąd dodania pliku")
            }
            navigate(`/projects/${projectResponse.id}`)
          } catch (error) {
            console.error("Błąd utworzenia grafu")
          }
        } catch (e: any) {
          console.error(e.message)
        }
      }
    }
  }

  return (
    <Box
      display="flex"
      gap="40px"
      justifyContent="center"
      alignItems="center"
      width='80%'>
      {file ? (
        <>
          <CircularProgress />
          <p>Your file is being processed. Please wait.</p>
        </>
      ) : (
        <>
          <Box width='40%'>
            <Dropzone acceptableFormats=".csv" file={file} setFile={onFileChange} />
          </Box>
          <Separator>
            <span />
            OR
            <span />
          </Separator>
          <Box textAlign="center">
            <ModelSampleDataModal />
          </Box>
        </>
      )}
    </Box>
  )
}

export default ModelSetData
