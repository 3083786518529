import axios from "axios";

export interface GraphSchema {
  id: string;
  name: string;
}

export interface ArgumentApiView {
  id?: string;
  name?: string;
  type?: any;
  existingValues?: ArgumentValueApiView[];
}

export interface ArgumentValueApiView {
  name?: string;
  value?: any;
  id?: string;
}

export interface PredictionRequestBody {
  outputArgumentId: string;
  entities: EntityApiView[];
}

export interface ArgumentImpactRequestBody {
  outputArgumentId: string;
}

export type ArgumentImpactResponseBody = {
  name: string;
  avgImpact: number;
}[];

export interface EntityApiView {
  key: string;
  values: ArgumentValueApiView[];
}

export type PredictionResult = Record<string, number>;

export type PredictionRequestResponse = {
  entity: EntityApiView;
  prediction: PredictionResult;
}[];
export type Metrics = Record<string, number>;
export type GraphInfo = {
  name: string;
  id: string;
};

export type SelectedInputValues = Record<string, ArgumentValueApiView>;

export const createGraph = async (name: string, projectId?: string) => {
  const response = await axios.post<GraphSchema>(`/api/graph/create?projectId=${projectId}`, { name });
  return response.data;
};

export const getGraphMeta = async (graph: string) => {
  const response = await axios.get<Metrics>("/api/graph/metrics", {
    params: { graph },
  });
  return response.data;
};

export const getGraphInfo = async (graph: string) => {
  const response = await axios.get<GraphInfo>(`/api/graph/${graph}`);
  return response.data;
};

export const graphArguments = async (graph: string) => {
  const response = await axios.get<ArgumentApiView[]>("/api/graph/arguments", {
    params: { graph },
  });
  return response.data;
};

export const getAll = async () => {
  const response = await axios.get<GraphSchema[]>("/api/graph");
  return response.data;
};

export const predict = async (
  graph: string,
  predictionRequestBody: PredictionRequestBody
) => {
  const response = await axios.post<PredictionRequestResponse>(
    "/api/graph/predict",
    { ...predictionRequestBody },
    { params: { graph } }
  );
  return response.data;
};

export const impactCalculate = async (
  graph: string,
  argumentImpactRequestBody: ArgumentImpactRequestBody
) => {
  const response = await axios.get<ArgumentImpactResponseBody>(
    "/api/argument/impact",
    { params: { ...argumentImpactRequestBody, graph } }
  );
  return response.data;
};

export const renameModel = async (
  graph: string,
  name: string
) => {
  await axios.put<any>(
    "/api/graph/rename", null,
    { params: { graph, name }}
  );
}

export const deleteModel = async (
  graph: string
) => {
  await axios.delete<any>(
    "/api/graph/delete",
    { params: { graph } }
  )
}