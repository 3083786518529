import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const Container = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  margin: '12px',
  width: '100%',
  height: '100px',
  backgroundColor: theme.palette.primary.light,
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: '4px',

  label: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
}))

const Description = styled('div')`
  position: absolute;
  padding: 0 30px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 8px;
`

const MAX_FILE_SIZE = 100 * 1000 * 1000

interface FileUploadProps {
  file: File | null
  setFile: (file: File | null) => void
  acceptableFormats: string
}

const consumeEvent = (e: any) => {
  e.preventDefault()
  e.stopPropagation()
}

const Dropzone: React.FC<FileUploadProps> = ({
  file,
  setFile,
  acceptableFormats,
}: FileUploadProps) => {
  const [isDragOver, setDragOver] = useState<boolean>(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    if (files && files[0]) {
      setFile(files[0])
    }
  }

  const readFile = (file: any) => {
    if (file.size > MAX_FILE_SIZE) return
    setFile(file)
  }

  const onDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const { files } = e.dataTransfer
    // eslint-disable-next-line prefer-destructuring
    const file = files[0]
    if (file) {
      setDragOver(false)
      readFile(file)
      // if (!acceptableFormats.includes(file.type)) return
    }
  }

  const onDragEnter = (e: React.DragEvent) => {
    consumeEvent(e)
  }

  const onDragOver = (e: React.DragEvent<HTMLElement>) => {
    consumeEvent(e)
    setDragOver(true)
  }

  const onDragLeave = (e: React.DragEvent) => {
    consumeEvent(e)
    setDragOver(false)
  }

  return (
    <Container>
      <input
        type="file"
        onChange={handleChange}
        id="file-upload-single"
        hidden
        accept={acceptableFormats}
      />
      <Description>
        {!file && (
          <Box sx={{ color: (theme) => theme.palette.primary.dark }}>
            Drop CSV or browse
          </Box>
        )}
      </Description>
      <label
        htmlFor="file-upload-single"
        style={{ ...(isDragOver ? { border: '1px solid black' } : {}) }}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
      />
    </Container>
  )
}

export default Dropzone
