import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  width: '100%',
  justifyContent: 'center',
  paddingTop: theme.spacing(1),
}))

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.secondary.light,

  ':hover': {
    color: theme.palette.secondary.main,
  },
}))

const Footer = () => {
  return (
    <Wrapper>
      <StyledLink to="#">Contact</StyledLink>
      <StyledLink to="#">Privacy</StyledLink>
      <StyledLink to="#">Terms & Conditions</StyledLink>
    </Wrapper>
  )
}

export default Footer
