import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/material/styles'
import { Link, NavLink } from 'react-router-dom'
import { useState } from 'react'
import { useProjectsState } from 'project/ProjectsProvider'
import { Button } from '@mui/material'
import UserService from 'UserService'
import { Logout } from '@mui/icons-material'

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(1),
  backgroundColor: '#5bacfa',
  borderRadius: '0 8px 0 8px',
  padding: theme.spacing(1, 2, 1, 1),
  textDecoration: 'none',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#1385f2',
  },
}))

const drawerWidth = 280

interface Props {
  children: React.ReactNode
}

export default function MenuAppBar({ children }: Props) {
  const [mobileOpen, setMobileOpen] = useState(false)
  const { projects } = useProjectsState()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box
      display="flex"
      flexDirection="column"
      gap="16px"
      padding="16px 16px 8px 16px"
      sx={{
        background: '#2c2c2c',
        height: '100vh',
      }}>
      <div
        style={{
          padding: '8px 2px 20px',
          borderBottom: '1px solid rgba(255,255,255,.2)',
        }}>
        <Link to="/">
          <img
            height={40}
            width={150}
            src="/GraphAI_logo-white.svg"
            alt="logo"
            style={{ display: 'block', margin: '10px auto 20px' }}
          />
        </Link>
        <StyledNavLink
          to={'/'}
          sx={{
            fontWeight: '500',
            textTransform: 'uppercase',
            padding: '12px 40px',
          }}>
          <AddIcon /> New Project
        </StyledNavLink>
        <Button
          style={{ margin: '16px 0', width: '100%' }}
          onClick={() => {
            UserService.doLogout({ redirectUri: `${window.location.origin}` })
          }}>
          <Logout /> Logout
        </Button>
      </div>

      <div style={{ overflowY: 'auto' }}>
        <Typography
          variant="subtitle1"
          sx={{
            color: 'rgba(255,255,255,.4)',
            textTransform: 'uppercase',
            fontSize: '14px',
            textAlign: 'center',
            marginBottom: '10px',
          }}>
          Your projects:
        </Typography>
        <Box>
          {projects.length > 0 &&
            projects.map((project) => (
              <StyledNavLink
                key={project.id}
                to={`/projects/${project.id}`}
                sx={{
                  bgcolor: 'transparent',
                  ':hover': { bgcolor: 'grey.900' },
                  '&.active': { bgcolor: 'grey.900' },
                }}>
                {project.name}
              </StyledNavLink>
            ))}
        </Box>
      </div>
    </Box>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          display: { xs: 'block', sm: 'none' },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Responsive drawer
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open>
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}>
        <Toolbar sx={{ display: { xs: 'block', sm: 'none' } }} />
        {children}
      </Box>
    </Box>
  )
}
