import { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { createProject, loadSample, getSampleDatasets } from 'models/project'
import { useProjectsState } from 'project/ProjectsProvider'
import { useNavigate } from 'react-router'

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
  minWidth: '300px',
  alignItems: 'center',
}))

const Sample = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.grey[300],
  borderRadius: '10px',

  ':hover': {
    backgroundColor: theme.palette.grey[400],
    cursor: 'pointer',
  },
}))

const ModelSampleDataModal = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [samples, setSamples] = useState<
    { id: string; name: string; sampleKey: string }[]
  >([])
  const { addProject } = useProjectsState()
  const navigate = useNavigate()

  useEffect(() => {
    handleLoadSamples()
  }, [])

  const handleLoadSamples = async () => {
    const response = await getSampleDatasets()
    setSamples(
      response.map((sample, idx) => {
        return {
          id: `${idx}`,
          name: sample.displayName,
          sampleKey: sample.sampleKey,
        }
      })
    )
  }

  const getSampleById = (id: string) =>
    samples.find((sample) => sample.id === id)!

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleSampleClick = async (id: string) => {
    try {
      const sample = getSampleById(id)
      const projectResponse = await createProject(sample.name)
      addProject(projectResponse)
      try {
        setLoading(true)
        await loadSample(projectResponse.id, sample.sampleKey)
        setOpen(false)
        setLoading(false)
        navigate(`/projects/${projectResponse.id}`)
      } catch (error) {
        console.error('Error loading sample data')
      }
    } catch (e: any) {
      console.error(e.message)
    }
  }

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>
        Use Sample Data
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {loading ? 'Loading sample dataset' : 'Select data set'}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Wrapper>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                {samples.map(({ id, name }) => (
                  <Sample key={id} onClick={() => handleSampleClick(id)}>
                    {name}
                  </Sample>
                ))}
              </>
            )}
          </Wrapper>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ModelSampleDataModal
